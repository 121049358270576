import { ReactNode, useState } from 'react'
import './index.scss'
import CountUp from 'react-countup'

export default function Starter(props: { children?: ReactNode }) {
  const [load, setLoad] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(true)

  return (
    <>
      <div className={`loading loading-${load ? 'hidden' : 'visible'}`} style={{ display: visible ? 'flex' : 'none' }}>
        <div></div>
        <div className="flex-align-center"></div>
        <div className="flex-align-center flex-justify-end">
          <p className="fs-36">
            <CountUp
              duration={3}
              delay={0}
              end={99}
              easingFn={(t, b, c, d) => {
                return c * (t /= d) * t * t * t + b
              }}
              onEnd={() => {
                setTimeout(() => {
                  setLoad(true)
                }, 800)
                setTimeout(() => {
                  setVisible(false)
                }, 4000)
              }}
            />
            <span className="ml-6">%</span>
          </p>
        </div>
        <div></div>
      </div>
      {props.children}
    </>
  )
}
