import * as ReactDOM from 'react-dom/client'
import './index.scss'
import { onresize, countRemPx } from './utils/rem'

import Starter from './components/starter'
import Home from './pages/home'

document.documentElement.style.fontSize = `${countRemPx()}px`
document.documentElement.style.width = `100%`
document.documentElement.style.height = `100%`
onresize(0, () => {
  document.documentElement.style.fontSize = `${countRemPx()}px`
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Starter>
    <Home></Home>
  </Starter>
)
