import { CSSProperties, ReactNode } from 'react'
import './index.scss'

export default function HoverBox(props: { children?: ReactNode; style?: CSSProperties; hover?: boolean }) {
  return (
    <div className={`hover-box hover-box-${props.hover ? 'active' : 'static'}`} style={props.style}>
      {props.children}
      <div className="hover-line"></div>
    </div>
  )
}
