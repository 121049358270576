export default function Foot() {
  const beian = '粤公网安备 44030602007494号'
  const beian2 = '｜粤ICP备20033747号-2'
  return (
    <div className="flex-between flex-align-center foot">
      {/* All rights reserved. */}
      <div className="fs-14 mobile-hidden">© {new Date().getFullYear()} NeedCodeStudio</div>
      <div className="flex-align-center fs-14 beian">
        <a
          className="flex-align-center"
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030602007494"
          rel="noreferrer"
        >
          <img className="beian-icon mr-6" src="beian.png" alt="beian" />
          <span>{beian}</span>
        </a>
        <a href="http://beian.miit.gov.cn" target="_blank" rel="noreferrer">
          {beian2}
        </a>
      </div>
    </div>
  )
}
