import { useState, useEffect, useCallback } from 'react'
import { CSSTransition } from 'react-transition-group'
import './index.scss'
import Head from './head'
import Foot from './foot'

import SliderBox from '../../components/slider-box'
import { px2vw } from '../../utils/rem'

let __timer: any = 0

type Project = {
  id: number
  name: string
  image: string
  color: string
  shot: string
  shotBg: string
  info: {
    label: string
    value: string | number
  }[]
}

export default function Home() {
  const [animation, setAnimation] = useState<boolean>(false)
  const [current, setCurrent] = useState<number>(0)
  const [projectSize] = useState<string>(px2vw(600))
  const [projectInfoSize] = useState<string>(px2vw(540))
  const [projects] = useState<Project[]>([
    {
      id: 1,
      name: 'Moonrise Web Store',
      image: 'project/1.jpg',
      color: 'rgba(207,223,7,1)',
      shot: '1',
      shotBg: 'var(--theme-project-detail-img-bg)',
      info: [
        {
          label: '公司',
          value: '当月花艺工作室'
        },
        {
          label: '类型',
          value: '网站'
        },
        {
          label: '年份',
          value: '2023.2'
        },
        {
          label: '网址(支持多尺寸设备浏览)',
          value: 'https://moonrise.com.cn'
        }
      ]
    },
    {
      id: 2,
      name: '快7充电生活小程序',
      image: 'project/2.jpg',
      color: 'rgba(52,125,255,1)',
      shot: '2',
      shotBg: '#ffffff',
      info: [
        {
          label: '公司',
          value: '深圳市快与慢充电桩服务有限公司'
        },
        {
          label: '类型',
          value: '微信小程序'
        },
        {
          label: '年份',
          value: '2023.4'
        },
        {
          label: '预览',
          value: '扫码体验'
        }
      ]
    }
  ])
  const [projectItemVisible, setProjectItemVisible] = useState<boolean>()
  const [projectItem, setProjectItem] = useState<Project>()

  const next = useCallback(() => {
    __timer = setTimeout(() => {
      setCurrent(val => {
        if (val + 1 >= projects.length) {
          return 0
        }
        return val + 1
      })
      next()
    }, 10000)
  }, [projects.length])

  const close = useCallback(() => {
    setProjectItemVisible(false)
    next()
    setTimeout(() => {
      setProjectItem(undefined)
    }, 1000)
  }, [next])

  const detail = useCallback((project: Project) => {
    setProjectItem(project)
    setTimeout(() => {
      setProjectItemVisible(true)
    }, 1000)
    __timer && clearTimeout(__timer)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setAnimation(true)
    }, 0)
  })

  useEffect(() => {
    __timer = setTimeout(() => {
      next()
    }, 10000)
    return () => {
      __timer && clearTimeout(__timer)
    }
  }, [next])

  return (
    <div className="needcode">
      <Head></Head>
      <div className="body flex-center mobile-hidden">
        <div className="project-wrap" style={{ width: `calc(${projectSize} + ${projectInfoSize})` }}>
          {projects.map((project, index) => {
            return (
              <div
                className="project flex-column flex-justify-center"
                key={project.id}
                style={{
                  width: projectInfoSize,
                  marginTop: project.id === 1 ? -(current * document.body.clientHeight) : 0,
                  opacity: index === current ? 1 : 0
                }}
              >
                <SliderBox active={animation} delay={4.6}>
                  <div className="fs-16">Project 0{project.id}.</div>
                </SliderBox>
                <SliderBox active={animation} delay={4.6}>
                  <div className="fs-42 mt-40">{project.name}</div>
                </SliderBox>
                <SliderBox active={animation} delay={4.6}>
                  <div
                    className="fs-14 mt-40 flex-center cursor-pointer btn"
                    style={{
                      opacity: projectItem && projectItem.id === project.id ? 0.5 : 1,
                      ['--hover-color' as any]: project.color
                    }}
                    onClick={() => {
                      detail(project)
                    }}
                  >
                    查看详情
                  </div>
                </SliderBox>
              </div>
            )
          })}
          <div
            className="project-poster"
            style={{ width: projectSize, height: projectSize, opacity: animation ? 1 : 0 }}
          >
            {projects.map((project, index) => {
              return (
                <div
                  key={project.id}
                  className="cursor-pointer"
                  style={{
                    top: index < current ? `-${projectSize}` : 0,
                    zIndex: 99 - project.id
                  }}
                  onClick={() => {
                    detail(project)
                  }}
                >
                  <div className="poster" style={{ backgroundImage: `url("${project.image}")` }}></div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="project-indicator flex-column">
          {projects.map((project, index) => {
            return (
              <div
                className={`cursor-pointer ${index === current ? 'active' : ''}`}
                key={project.id}
                onClick={() => {
                  __timer && clearTimeout(__timer)
                  setCurrent(index)
                  next()
                }}
              ></div>
            )
          })}
        </div>
        <div
          className={`project-animation project-animation-${projectItem && projectItem.color ? 'visible' : 'hidden'}`}
          style={{ backgroundColor: projectItem && projectItem.color }}
        ></div>
        <CSSTransition in={projectItemVisible} unmountOnExit timeout={300} classNames="project-detail-transition">
          <div className="project-detail">
            <i
              className="nc-icon nc-guanbi fs-30 cursor-pointer project-detail_close"
              style={{ left: 'var(--body-padding-num)' }}
              onClick={close}
            ></i>
            <span
              className="project-detail_close cursor-pointer fs-18 fw-bold"
              onClick={close}
              style={{ right: 'var(--body-padding-num)' }}
            >
              CLOSE
            </span>
            <div className="project-detail_info" style={{ width: `calc(${projectSize} + ${projectInfoSize})` }}>
              <div style={{ height: '10vh' }}></div>
              <div className="list">
                <div className="flex">
                  <div className="image">
                    <img
                      src={`project/shot/${projectItem?.shot}.png`}
                      alt="needcodestudio"
                      style={{ backgroundColor: projectItem?.shotBg }}
                    ></img>
                  </div>
                </div>
                <div className="fs-32 fw-bold pb-16 mt-68">基本信息</div>
                {projectItem?.info.map((item, index) => {
                  return (
                    <div key={index} className="item flex-between flex-align-center">
                      <div className="fs-16">{item.label}</div>
                      <div className="fs-16">
                        {/http/.test(`${item.value}`) ? (
                          <a href={`${item.value}`} target="_blank" rel="noreferrer">
                            {item.value}
                          </a>
                        ) : (
                          item.value
                        )}
                      </div>
                    </div>
                  )
                })}
                {projectItem?.id === 1 ? (
                  <>
                    <div className="fs-32 fw-bold pb-30 pt-70">项目介绍</div>
                    <div className="fs-16 lh-28">
                      <p className="fw-bold mb-14">当月花艺工作室</p>
                      <p>
                        基于深圳的创意型花艺工作室。是一家静谧，探寻自然本质，以设计加持的美学花艺工作室。挖掘人与自然间互动的可能性，提供更多美好的生活方式，当月为每个在意⽣活的⼈、每个敏感的情绪、每个期待的场景提供专业的定制「回应」。
                      </p>
                      <p className="fw-bold mb-20 mt-48">线上花店-「当月商店」</p>
                      <p className="mb-48">
                        从实体到线上，当月在三年里经历了很多，见证了许多人与事，这些幸福瞬间让当月更加坚定,希望把鲜花带到每个人的生活中。所以当月推出官方网站，是希望鲜花不要被时间与地点所限制，愿所有情绪都能及时被拥抱。
                      </p>
                      <p className="text-center">
                        <img
                          style={{ width: '70%' }}
                          src="https://needcode.oss-cn-shenzhen.aliyuncs.com/common/web/m.gif"
                          alt=""
                        />
                      </p>
                      <p className="fw-bold mb-20 mt-68">自助你的鲜花-「DIY CENTER」</p>
                      <p className="mb-48">
                        为了探索新型有趣的用花体验，NeedCodeStudio与当月还研发配备了一个有趣的体验性版块-「DIY
                        CENTER」：用户可以在此自由选配自己喜欢的花，亲手为花礼加上一份浪漫与心意。在「DIY
                        CENTER」里，用户通过选取的方式轻松完成定制，我们希望尽量降低用户的学习成本，让更多的人也能得到愉悦有趣的定制体验。
                      </p>
                      <p className="text-center">
                        <img
                          style={{ width: '70%' }}
                          src="https://needcode.oss-cn-shenzhen.aliyuncs.com/common/web/m2.gif"
                          alt=""
                        />
                      </p>
                    </div>
                  </>
                ) : (
                  ''
                )}
                <div style={{ height: '10vh' }}></div>
                <div className="text-center">
                  <p className="fs-18 fw-bold">END.</p>
                </div>
              </div>
              <div style={{ height: 'var(--body-padding-num)' }}></div>
            </div>
          </div>
        </CSSTransition>
      </div>
      <div className="body pc-hidden flex-center">
        <div className="fs-24">coming soon...</div>
      </div>
      <Foot></Foot>
    </div>
  )
}
