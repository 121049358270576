import { useState, useCallback } from 'react'
import HoverBox from '../../components/hover-box'

import About from '../about'

export default function Head() {
  const [visible, setVisible] = useState(false)
  const toggle = useCallback(() => {
    setVisible(() => {
      return !visible
    })
  }, [visible])

  return (
    <>
      <div className="flex-between flex-align-center transition head">
        <div>
          <div className="logo transition"></div>
        </div>
        <div className="mt-pc-8 mt-m-2" onClick={toggle}>
          <HoverBox hover={visible}>
            <div className="pl-4 pr-4 fs-18 transition">ABOUT</div>
          </HoverBox>
        </div>
      </div>
      <div className={`head-modal flex-column head-modal-${visible ? 'open' : 'close'}`}>
        <div className="head-modal_head flex-between flex-align-center flex-shrink">
          <div>
            <div className="logo transition cursor-pointer" onClick={toggle}></div>
          </div>
          <div
            className="mt-pc-6 mt-m-2 pl-4 pr-4 fs-18 cursor-pointer"
            style={{ color: 'var(--theme-color)' }}
            onClick={toggle}
          >
            CLOSE
          </div>
        </div>
        <div className="flex-grow">
          <About visible={visible}></About>
        </div>
      </div>
    </>
  )
}
