import './index.scss'
import SliderBox from '../../components/slider-box'

export default function About(props: { visible: boolean }) {
  return (
    <div className="about pt-pc-50 pl-pc-50 pr-pc-50 pt-m-24 pl-m-24 pr-m-24">
      <SliderBox active={props.visible} time={1}>
        <div>
          <p className="fs-16 lh-24">
            &lt; 需要代码 &gt; 工作室，位于广东深圳
            <br />
            我们提供专业、高效、可靠的小程序、网站定制开发服务，满足各行业企业和个人的需求。
          </p>
          <p className="fs-16 lh-24 mt-20">
            我们注重用户体验/界面设计，以技术支撑产品，链接商业，设计，计算能力，为用户提供
            <br />
            专业的人机交互体验，让用户在使用时也能享受程序设计带来的乐趣。
          </p>
          <p className="fs-16 lh-24 mt-20">
            当今数字时代，软件开发是各个行业不可或缺的部分，我们不断研究探索更多的可能性，
            <br />
            以确保我们能够为客户提供好用、低成本的软件解决方案。
          </p>
        </div>
      </SliderBox>
      <div style={{ height: '100px' }}></div>
      <div className="circle-wrap mobile-hidden">
        <div className="circle fs-14">联系方式</div>
        <div className="circle fs-14 flex-center">
          <i className="nc-icon nc-shouji fs-18 mr-2"></i>
          <span>15986907592</span>
        </div>
        <div className="circle fs-14 flex-center">
          <i className="nc-icon nc-wechat fs-20 mr-6"></i>
          <span>simsir_lin</span>
        </div>
      </div>
      {/* <SliderBox active={props.visible} time={1}>
        <div className="info">
          <p className="fs-14 lh-28">位置: 广东,深圳</p>
          <p className="fs-14 lh-28">邮箱: 15986907592@163.com</p>
          <p className="fs-14 lh-28">微信: simsir_lin</p>
          <img src="https://needcode.oss-cn-shenzhen.aliyuncs.com/common/file/giphy.webp" alt="" />
        </div>
      </SliderBox> */}
    </div>
  )
}
