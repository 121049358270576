import { CSSProperties, ReactNode } from 'react'
import './index.scss'

export default function SliderBox(props: {
  children?: ReactNode
  style?: CSSProperties
  active?: boolean
  time?: number
  delay?: number
}) {
  const { delay = 0.4, time = 0.6, active = false } = props
  return (
    <div className="slider-box" style={props.style}>
      <div style={{ opacity: 0 }}>{props.children}</div>
      <div
        className={`slider-box_children ${active ? 'active' : 'normal'}`}
        style={{ transition: `all ${time}s ${delay}s` }}
      >
        {props.children}
      </div>
    </div>
  )
}
